<template>
        <section class="products-holder" :color="`${zamuPurple2}`" >  
            <v-layout justify-space-around justify-center>
                <v-flex xs9 sm9 offset-sm1 class="mt-10">
                         <v-card flat tile color="" width="92%" class="transparent main-content-products">
                            <v-card-title primary-title class="justify-center">
                                    <h3 v-if="!is_screen_small" class="zamu-heading transact-heading product-heading">Key Services</h3><br />
                                   <h3 v-if="is_screen_small" class="zamu-heading transact-heading product-heading">Key <br />Services</h3><br />

                                    <v-card tile flat :color="`${zamuGrey1}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-white"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-row align="center" justify="center">
                                <v-col cols="12"  lg="6" md="12" sm="12" xs="12" class="" v-for="(product, index) in strengths" :key="index">
                                    <v-card tile flat :color="`${product.color}`" :class="{'fixed-height' :!is_screen_small }" align="center" justify="center">
                                        <v-card-text class="section-services" align="left" justify="left">
                                            <v-row>
                                                <v-col cols="12"  lg="3" md="3" sm="12" xs="12" align="center" justify="center">
                                                    <span v-if="Array.isArray(product.image)">
                                                        <template v-for="(image, i) in product.image">
                                                          <v-img :src="require(`../../../../assets/images/${image}`)" width="50" class="" :key="i"></v-img>
                                                        </template>


                                                    </span>
                                                    <span v-else>
                                                    <v-img :src="require(`../../../../assets/images/${product.image}`)" width="32" class="product-img"></v-img>


                                                    </span>
                                                </v-col>
                                                <v-col  lg="9" md="9" sm="12" xs="12">
                                                    <h3 class="zamu-sub-heading product-header mt-6">{{ product.title }}</h3>
                                                    <p class="mt-4">{{ product.text }}</p>
                                                </v-col>

                                            </v-row>
                                        </v-card-text>                                    
                                    </v-card>
                                </v-col>
                            </v-row>
                         </v-card>
                </v-flex>
            </v-layout>
        </section>
  
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: 'Strengths',
    mixins: [colorMixin, coreMixin],
    props: ['strengths', 'title'],

}
</script>

<style scoped>
.fixed-height {
    height: 210px;
}
.product-heading {
    font-size: 40px !important;
}
</style>